import React from 'react';
import Image from 'next/image';
import PaidIcon from '@mui/icons-material/Paid';
import ShareIcon from '@mui/icons-material/Share';
import ReplayIcon from '@mui/icons-material/Replay';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import {Box, Button, Container, Divider, Grid, Paper, Stack, Typography} from '@mui/material';

import Layout from '@/components/layout';

import logoMainImg from '../../public/img/logo-main.png';

const IndexPage = (): React.JSX.Element => {

  const handleClick = (): void => {
    const anchor: Element | null = document.querySelector('#hand-list');
    if (anchor) anchor.scrollIntoView({behavior: 'smooth', block: 'center'});
  };

  return (
    <Layout title={'Golito | Administra listas y pagos para tus eventos en equipo'} path={''} description={'Administra las listas y los pagos de tus amigos y conocidos para tus eventos en equipo'}>
      <Box sx={{bgcolor: '#121212', pt: 8, pb: 0}}>
        <Container maxWidth='sm'>
          <Typography component='h1' align='center' color='white' gutterBottom className='HiSpammer'>
            Golito
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', pb: 3}}>
            <Image className='FlexImg' src={logoMainImg} alt='Imagen de presentación de Golito' title='Golito' priority={true}/>
          </Box>
          <Typography component='p' align='center' color='#FAFAFA' paragraph sx={{fontSize: '1.5rem', lineHeight: 1.334}}>
            <b>Golito</b> te permite administrar listas y pagos de amigos para tus eventos en equipo
          </Typography>
          <Stack sx={{pt: 4}} direction='row' spacing={2} justifyContent='center'>
            <Button component={'a'} color='secondary' variant='contained' href='https://app.golito.co' rel='noopener' title='Acceder a la aplicación'>
              Acceder a la app
            </Button>
          </Stack>
          <Stack sx={{pt: 12, pb: 1}} direction='row' spacing={2} justifyContent='center'>
            <Button sx={{display: 'flex', flexDirection: 'column', lineHeight: 0.7}} color='secondary' variant='text' type='button' title='Aprende más sobre Golito' onClick={() => handleClick()} data-umami-event='know-more-btn'>
              <Box sx={{mt: '4px'}}>
                Saber más
              </Box>
              <Box sx={{mt: '0', mb: '-5px'}}>
                <ExpandMoreIcon width={0}/>
              </Box>
            </Button>
          </Stack>
        </Container>
      </Box>

      <Container sx={{py: {xs: 2, sm: 6}}} maxWidth='md' disableGutters>
        <Grid container spacing={4} direction='column'>
          <Grid item xs={1}>
            <Paper sx={{py: 3, px: 4, display: {xs: 'inline-block', sm: 'flex'}}} elevation={0}>
              <Box sx={{width: {xs: '100%', sm: '35%'}, display: 'flex', alignItems: 'center', justifyContent: 'center', mr: {xs: 0, sm: 2}, mb: {xs: 3, sm: 0}}}>
                <FormatListNumberedIcon sx={{fontSize: '72px', color: '#FFC107'}} width={0}/>
              </Box>
              <Box>
                <Typography component='h2' sx={{fontWeight: '700', mb: 2, lineHeight: 1.2, textTransform: 'uppercase'}}>
                  No hagas más listas a mano
                </Typography>
                Sólo debes compartir el enlace de tu evento en equipo por cualquier medio de comunicación y deja que la aplicación se encargue de la administración de la lista de inscritos por orden de fecha de inscripción
              </Box>
            </Paper>
          </Grid>
          <Divider sx={{mt: 3, mb: 1}}/>
          <Grid item xs={1}>
            <Paper sx={{py: 3, px: 4, display: {xs: 'inline-block', sm: 'flex'}}} elevation={0}>
              <Box>
                <Typography id='hand-list' component='h2' sx={{fontWeight: '700', mb: 2, lineHeight: 1.2, textTransform: 'uppercase'}}>
                  Comparte una vez la información del evento
                </Typography>
                Ya no es necesario que especifiques a cada uno de los participantes los datos del evento: dirección, lugar, valor del aporte, etc. Simplemente escríbelos una vez en la aplicación y todos sabrán esa información. Además, si necesitas hacer cambios, todos los inscritos recibirán notificaciones de actualización.
              </Box>
              <Box sx={{width: {xs: '100%', sm: '35%'}, display: 'flex', alignItems: 'center', justifyContent: 'center', ml: {xs: 0, sm: 2}, mt: {xs: 3, sm: 0}}}>
                <ShareIcon sx={{fontSize: '72px', color: '#FFC107'}} width={0}/>
              </Box>
            </Paper>
          </Grid>
          <Divider sx={{mt: 3, mb: 1}}/>
          <Grid item xs={1}>
            <Paper sx={{py: 3, px: 4, display: {xs: 'inline-block', sm: 'flex'}}} elevation={0}>
              <Box sx={{width: {xs: '100%', sm: '35%'}, display: 'flex', alignItems: 'center', justifyContent: 'center', mr: {xs: 0, sm: 2}, mb: {xs: 3, sm: 0}}}>
                <ReplayIcon sx={{fontSize: '72px', color: '#FFC107'}} width={0}/>
              </Box>
              <Box>
                <Typography component='h2' sx={{fontWeight: '700', mb: 2, lineHeight: 1.2, textTransform: 'uppercase'}}>
                  Programación inteligente de eventos
                </Typography>
                ¿Es un evento recurrente todas las semanas? Golito te permite crear eventos repetitivos basados en ciertas reglas, de tal manera que solo debes crear el evento una vez y automáticamente se actualizará para cada semana, sin necesidad de hacer algo adicional de tu parte.
              </Box>
            </Paper>
          </Grid>
          <Divider sx={{mt: 3, mb: 1}}/>
          <Grid item xs={1}>
            <Paper sx={{py: 3, px: 4, display: {xs: 'inline-block', sm: 'flex'}}} elevation={0}>
              <Box>
                <Typography component='h2' sx={{fontWeight: '700', mb: 2, lineHeight: 1.2, textTransform: 'uppercase'}}>
                  ¿Necesitas cobrar un aporte para el evento?
                </Typography>
                Gracias a nuestra integración con <a href='https://nequi.com.co' target='_blank' rel='nofollow noreferrer noopener' title='Ir a la página de Nequi' className={'DarkLightLink'}>Nequi</a>&#174; y <a href='https://wompi.co/' target='_blank' rel='nofollow noreferrer noopener' title='Ir a la página de Wompi' className={'DarkLightLink'}>Bancolombia</a>&#174;, puedes recibir pagos a través de estos medios, de esta manera podrás administrar mucho mejor el cobro de tus eventos y no tener que recibir dinero en efectivo en pleno siglo XXI. Si algo ocurre, también podrás hacer devoluciones de estos montos.
              </Box>
              <Box sx={{width: {xs: '100%', sm: '35%'}, display: 'flex', alignItems: 'center', justifyContent: 'center', ml: {xs: 0, sm: 2}, mt: {xs: 3, sm: 0}}}>
                <PaidIcon sx={{fontSize: '72px', color: '#FFC107'}} width={0}/>
              </Box>
            </Paper>
          </Grid>
          <Divider sx={{mt: 3, mb: 1}}/>
          <Grid item xs={1}>
            <Paper sx={{py: 3, px: 4, display: {xs: 'inline-block', sm: 'flex'}}} elevation={0}>
              <Box sx={{width: {xs: '100%', sm: '35%'}, display: 'flex', alignItems: 'center', justifyContent: 'center', mr: {xs: 0, sm: 2}, mb: {xs: 3, sm: 0}}}>
                <DevicesOtherIcon sx={{fontSize: '72px', color: '#FFC107'}} width={0}/>
              </Box>
              <Box>
                <Typography component='h2' sx={{fontWeight: '700', mb: 2, lineHeight: 1.2, textTransform: 'uppercase'}}>
                  Acceso desde cualquier dispositivo
                </Typography>
                Puedes acceder la aplicación desde cualquiera de tus dispositivos: desde tu PC, desde tu tablet o desde tu dispositivo móvil. Siempre verás la información actualizada y los cambios podrás verlos en tiempo real ¡No debes estar recargando la aplicación para ver la información de quienes se están inscribiendo a tu evento!
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{pb: 8, mt: 2, mb: 2}} maxWidth='md' fixed>
        <Paper sx={{p: 3, display: 'inline-block', textAlign: 'center', bgcolor: '#FFC107'}} color='primary.main' elevation={3}>
          <Typography component='h2' color='primary.main' sx={{fontWeight: '700', mb: 2, lineHeight: 1.2, textTransform: 'uppercase', width: '100%'}}>
            Lo mejor de todo: ¡Es gratis para que la pruebes!
          </Typography>
          <Typography color='primary.main'>
            Hemos creado esta aplicación con el propósito de que la actividad de planificar un evento en equipo de cualquier tipo no sea una tortura. Organizar a las personas, confirmar quienes asistirán y quienes no, cobrar un aporte a cada una de ellas, entre otras acciones, son tareas que alguna vez hemos vivido y que son muy tediosas y terminan por desgastar la energía del organizador, energía que puede guardar para realizar otras actividades como ¡disfrutar de la actividad en si misma! Entonces ¿Qué estás esperando para probarla? Regístrate y comienza a organizar tus eventos de una manera más fácil y rápida!
          </Typography>
          <Stack sx={{pt: 4}} direction='row' spacing={2} justifyContent='center'>
            <Button component={'a'} variant='contained' href='https://app.golito.co' rel='noopener' title='Acceder a la aplicación'>
              Acceder
            </Button>
          </Stack>
        </Paper>
      </Container>
    </Layout>
  );
};

export default IndexPage;
